<template>
  <v-container>
    <v-row>
      <v-col
        cols=3
      >
        <v-form v-model="valid">
          <p>
            <v-text-field
              v-model="submitData.url"
              label="Url"
              :rules="[required('Url')]"
            />
          </p>
          <p>
            <v-text-field
              v-model="submitData.link"
              label="Link"
              :rules="[required('Link')]"
            />
          </p>
          <br>
          <v-row>
            <v-col
              cols=6
            >
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="AddNew"
              >
                Thêm mới
              </v-btn>
            </v-col>
            <v-col
              cols=6
            >
              <v-btn
                color="error"
                :disabled="selected.length === 0"
                @click="DeleteAll"
              >
                Xóa
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col
        cols=9
      >
        <v-card>
          <v-card-title>Danh sách</v-card-title>
          <v-card-text>
            <v-responsive max-height="600px" aspect-ratio="16/9" class="overflow-y-auto">
              <v-data-table
                v-model="selected"
                :loading="loading"
                :headers="headers"
                :items="items"
                :search="search"
                :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
                loading-text="Đang tải dữ liệu..."
                show-select
              >
                <template v-slot:[`item.url`]="{ item }" >
                  <v-btn
                    v-clipboard:copy="ParseUrl(item.url)"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    dark
                  >
                    {{item.url}}
                  </v-btn>
                </template>
              </v-data-table>
            </v-responsive>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import base from '@/config/base'
  import headers from '@/config/header'
  import { db } from '@/plugins/db'
  import validations from '@/plugins/helpers/validations'
  export default Vue.extend({
    name: 'Home',
    components: {
    },
    data() {
      return {
        ...base,
        headers,
        loading: false,
        search: '',
        valid:false,
        submitData: {
          url: '',
          link: '',
        },
        selected: [],
        documents: [],
        filter: []
      }
    },
    computed:{
      items() {
        if (!this.documents) {
          return []
        }
        return this.documents.map(
          (items, index) => {
            const r = {
              ...items,
              id: items.id,
              index: index+1
            }
            return r
        })
      }
    },
    methods:{
      ...validations,
      ParseUrl(url){
        return this.URL+url
      },
      onCopy(){
        this.$toast.success('Đã copy thành cồng !!!')
      },
      onError(e){
        this.$toast.error(`Lỗi copy: ${e}`)
      },
      DeleteAll(){
        this.selected.map(item=>{
          db.collection('links').doc(item.id).delete()
        })
        this.selected = []
        this.$toast.success('Đã xóa thành công !!!')
      },
      AddNew(){
        db
          .collection('links')
          .where('url', '==', this.submitData.url)
          .get()
          .then(resp=>{
            if(resp.empty){
              db.collection('links').add(this.submitData).then(resp=>{
                  this.$toast.success('Đã thêm thành công !!!')
                  this.submitData = {
                    url: '',
                    link: '',
                  }
                })
                .catch(e=>{
                  console.log(e)
                  this.$toast.error(e.message)
                })
            }else{
              this.$toast.error('URL đã tồn tại !!!')
            }
          })
          .catch(e=>{
            console.log(e)
            this.$toast.error(e.message)
          })  
      }
    },
    firestore: {
      documents: db.collection('links'),
    },
  })
</script>
